import axios from 'axios'
import router from '../router'

const baseUrl = 'https://tanpopo.sixcore.jp/minidoc'
axios.defaults.baseURL = baseUrl

const state = {
  authData: null,
  token: null,
  username: null,
  isAuthenticated: false
}

const getters = {
  authData: (state) => state.authData,
  token: (state) => state.token,
  username: (state) => state.username,
  isAuthenticated: (state) => state.isAuthenticated
}

const mutations = {
  authData (state, authData) {
    state.authData = authData
  },
  token (state, token) {
    if (token) {
      state.token = token
      localStorage.setItem('token', token)
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
    } else {
      state.token = null
      localStorage.removeItem('token')
      axios.defaults.headers.common.Authorization = ''
    }
  },
  username (state, username) {
    state.username = username
    localStorage.setItem('username', username)
  },
  isAuthenticated (state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated
    localStorage.setItem('authenticated', true)
  }
}

const actions = {
  login ({ dispatch }, data) {
    localStorage.removeItem('authenticated')
    localStorage.removeItem('token')
    dispatch('resolveName', data)
  },
  async resolveName ({ dispatch }, data) {
    await axios
      .post('/wp-json/tpd/v1/username', {
        username: data.username
      })
      .then((response) => {
        if (response.data) {
          dispatch('getToken', {
            username: response.data,
            password: data.password
          })
        } else {
          alert('無効なユーザー名です')
        }
      })
      .catch(() => {
        console.log('resolve name error')
      })
  },
  getToken ({ commit }, data) {
    axios
      .post('/wp-json/jwt-auth/v1/token', {
        username: data.username,
        password: data.password
      })
      .then((response) => {
        commit('token', response.data.token)
        commit('username', data.username)
        commit('isAuthenticated', true)
        router.push('/')
      })
      .catch(() => {
        console.log('get token error')
        alert('パスワードが違います')
      })
  },
  // 自動ログイン
  autoLogin ({ commit, dispatch }) {
    const token = localStorage.getItem('token')
    if (!token) {
      console.log('token not found')
      return
    }
    commit('token', token)
    dispatch('varidateToken')
  },

  // Token有効性確認
  varidateToken ({ commit }) {
    console.log('validate login')
    commit('isAuthenticated', localStorage.getItem('authenticated'))
    axios
      .post('/wp-json/jwt-auth/v1/token/validate')
      .then(() => {
        console.log('Token Validation success')
        commit('isAuthenticated', true)
        router.push('/')
      })
      .catch(() => {
        console.log('Token Validation error')
        commit('isAuthenticated', false)
        router.push('/login')
      })
  },
  // ログアウト
  logout ({ commit }) {
    commit('authData', null)
    commit('token', null)
    commit('username', null)
    commit('isAuthenticated', false)
    router.push('/login')
  }
}

const Authorize = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default Authorize
