<template>
  <div id="history">
    <div class="teethseries">
      <ul class="upper left">
        <li class="UL1" :class="[{logged: isLogged('UL1')}, {selected: selected === 'UL1'}, {other: byOther('UL1')}]" @click="tooth">1</li>
        <li class="UL2" :class="[{logged: isLogged('UL2')}, {selected: selected === 'UL2'}, {other: byOther('UL2')}]" @click="tooth">2</li>
        <li class="UL3" :class="[{logged: isLogged('UL3')}, {selected: selected === 'UL3'}, {other: byOther('UL3')}]" @click="tooth">3</li>
        <li class="UL4" :class="[{logged: isLogged('UL4')}, {selected: selected === 'UL4'}, {other: byOther('UL4')}]" @click="tooth">4</li>
        <li class="UL5" :class="[{logged: isLogged('UL5')}, {selected: selected === 'UL5'}, {other: byOther('UL5')}]" @click="tooth">5</li>
        <li class="UL6" :class="[{logged: isLogged('UL6')}, {selected: selected === 'UL6'}, {other: byOther('UL6')}]" @click="tooth">6</li>
        <li class="UL7" :class="[{logged: isLogged('UL7')}, {selected: selected === 'UL7'}, {other: byOther('UL7')}]" @click="tooth">7</li>
        <li class="UL8" :class="[{logged: isLogged('UL8')}, {selected: selected === 'UL8'}, {other: byOther('UL8')}]" @click="tooth">8</li>
      </ul>
      <ul class="upper right">
        <li class="UR1" :class="[{logged: isLogged('UR1')}, {selected: selected === 'UR1'}, {other: byOther('UR1')}]" @click="tooth">1</li>
        <li class="UR2" :class="[{logged: isLogged('UR2')}, {selected: selected === 'UR2'}, {other: byOther('UR2')}]" @click="tooth">2</li>
        <li class="UR3" :class="[{logged: isLogged('UR3')}, {selected: selected === 'UR3'}, {other: byOther('UR3')}]" @click="tooth">3</li>
        <li class="UR4" :class="[{logged: isLogged('UR4')}, {selected: selected === 'UR4'}, {other: byOther('UR4')}]" @click="tooth">4</li>
        <li class="UR5" :class="[{logged: isLogged('UR5')}, {selected: selected === 'UR5'}, {other: byOther('UR5')}]" @click="tooth">5</li>
        <li class="UR6" :class="[{logged: isLogged('UR6')}, {selected: selected === 'UR6'}, {other: byOther('UR6')}]" @click="tooth">6</li>
        <li class="UR7" :class="[{logged: isLogged('UR7')}, {selected: selected === 'UR7'}, {other: byOther('UR7')}]" @click="tooth">7</li>
        <li class="UR8" :class="[{logged: isLogged('UR8')}, {selected: selected === 'UR8'}, {other: byOther('UR8')}]" @click="tooth">8</li>
      </ul>
      <ul class="lower left">
        <li class="LL1" :class="[{logged: isLogged('LL1')}, {selected: selected === 'LL1'}, {other: byOther('LL1')}]" @click="tooth">1</li>
        <li class="LL2" :class="[{logged: isLogged('LL2')}, {selected: selected === 'LL2'}, {other: byOther('LL2')}]" @click="tooth">2</li>
        <li class="LL3" :class="[{logged: isLogged('LL3')}, {selected: selected === 'LL3'}, {other: byOther('LL3')}]" @click="tooth">3</li>
        <li class="LL4" :class="[{logged: isLogged('LL4')}, {selected: selected === 'LL4'}, {other: byOther('LL4')}]" @click="tooth">4</li>
        <li class="LL5" :class="[{logged: isLogged('LL5')}, {selected: selected === 'LL5'}, {other: byOther('LL5')}]" @click="tooth">5</li>
        <li class="LL6" :class="[{logged: isLogged('LL6')}, {selected: selected === 'LL6'}, {other: byOther('LL6')}]" @click="tooth">6</li>
        <li class="LL7" :class="[{logged: isLogged('LL7')}, {selected: selected === 'LL7'}, {other: byOther('LL7')}]" @click="tooth">7</li>
        <li class="LL8" :class="[{logged: isLogged('LL8')}, {selected: selected === 'LL8'}, {other: byOther('LL8')}]" @click="tooth">8</li>
      </ul>
      <ul class="lower right">
        <li class="LR1" :class="[{logged: isLogged('LR1')}, {selected: selected === 'LR1'}, {other: byOther('LR1')}]" @click="tooth">1</li>
        <li class="LR2" :class="[{logged: isLogged('LR2')}, {selected: selected === 'LR2'}, {other: byOther('LR2')}]" @click="tooth">2</li>
        <li class="LR3" :class="[{logged: isLogged('LR3')}, {selected: selected === 'LR3'}, {other: byOther('LR3')}]" @click="tooth">3</li>
        <li class="LR4" :class="[{logged: isLogged('LR4')}, {selected: selected === 'LR4'}, {other: byOther('LR4')}]" @click="tooth">4</li>
        <li class="LR5" :class="[{logged: isLogged('LR5')}, {selected: selected === 'LR5'}, {other: byOther('LR5')}]" @click="tooth">5</li>
        <li class="LR6" :class="[{logged: isLogged('LR6')}, {selected: selected === 'LR6'}, {other: byOther('LR6')}]" @click="tooth">6</li>
        <li class="LR7" :class="[{logged: isLogged('LR7')}, {selected: selected === 'LR7'}, {other: byOther('LR7')}]" @click="tooth">7</li>
        <li class="LR8" :class="[{logged: isLogged('LR8')}, {selected: selected === 'LR8'}, {other: byOther('LR8')}]" @click="tooth">8</li>
      </ul>
    </div>
    <div id="histories">
      <ul>
        <li
          v-for="log in logs"
          :key="log.ID"
        >
          <button
            @click="deleteComment(log.ID)"
          >×</button>
          <header>
            <div class="other" v-if="log.data.other">他院処置</div>
            <div v-else>
              <div class="date">{{log.data.date}}</div>
              <div class="tantou">{{log.tantou}}</div>
            </div>
            <div class="target">{{log.data.target}}</div>
          </header>
          <div>{{decodeURI(log.data.comment)}}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      logged: [],
      other: [],
      selected: '',
      logs: []
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters['Log/history'],
      (newValue) => {
        this.logged = newValue
        // console.log(newValue)
      }
    )
    this.$store.watch(
      (state, getters) => getters['Log/other'],
      (newValue) => {
        this.other = newValue
      }
    )
  },
  computed: {
    isLogged () {
      return (tooth) => {
        // const logged = this.$store.getters['Log/history']
        const logged = { ...this.logged }
        const arr = []
        if (logged !== null && logged !== undefined) {
          Object.keys(logged).forEach(function (key) {
            arr.push(logged[key])
          })
          return arr.indexOf(tooth) !== -1
        } else {
          return false
        }
      }
    },
    byOther () {
      return (tooth) => {
        const other = { ...this.other }
        const arr = []
        if (other !== null && other !== undefined) {
          Object.keys(other).forEach(function (key) {
            arr.push(other[key])
          })
          return arr.indexOf(tooth) !== -1
        } else {
          return false
        }
      }
    }
  },
  methods: {
    async tooth (e) {
      this.selected = e.srcElement.classList[0]
      const userData = this.$store.getters['Log/userData']
      await axios
        .post('/wp-json/tpd/v1/tooth-history/', {
          pnum: userData.patientno,
          target: e.target.classList[0]
        })
        .then((response) => {
          this.logs = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    deleteComment (id) {
      if (confirm('記録を削除しますか？')) {
        this.logs = this.logs.filter((item) => item.ID !== id)
        this.$store.dispatch('Log/deleteComment', id)
      }
    }
  }
}
</script>

<style scoped>
  @media screen {
    .teethseries li.logged {
      background-color: #9de83c;
    }
    .teethseries li.other {
    background-image: linear-gradient(to bottom right,#dddddd 50%,#ffffff 51%);
    }
    .teethseries li.logged.other {
      background-image: linear-gradient(to bottom right,#dddddd 50%,#9de83c 51%);
    }
  }
  @media print {
    .teethseries li.logged {
      background-color: #555;
      color: #fff;
    }
    .teethseries li.other {
    background-image: linear-gradient(to bottom right,#222222 50%,#ffffff 51%);
    }
    .teethseries li.logged.other {
      background-image: linear-gradient(to bottom right,#222222 50%,#777777 51%);
    }
  }
  .teethseries li {
    position: relative;
  }
  .teethseries li.selected {
    border: 2px solid yellow;
  }
</style>
