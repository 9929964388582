<template>
  <div class="logdetail">
    <header id="pageheader">
      <h1>診察記録</h1>
      <div
        class="control"
        v-if="addnew"
      >
        <button
          @click="updateLog"
        >保存</button>
        <button
          @click="goback"
        >キャンセル</button>
      </div>
      <div
        class="control"
        v-else-if="edit"
      >
        <button
          @click="updateLog"
        >保存</button>
        <button
          @click="toggleEdit"
        >キャンセル</button>
      </div>
      <div
        class="control"
        v-else
      >
        <button
          @click="toggleEdit"
        >編集</button>
        <button
          @click="deleteLog"
        >削除</button>
      </div>
    </header>
    <section>
      <div class="logsummary">
        <dl>
          <dt>区分</dt>
          <dd>
            <select
              v-model="logData.payment"
              :disabled="!edit"
            >
              <option value="">--</option>
              <option value="insurance">保険診療</option>
              <option value="self">自由診療</option>
            </select>
          </dd>
        </dl>
        <dl>
          <dt>何回目</dt>
          <dd>
            <select
              v-model="logData.numoftimes"
              :disabled="!edit"
            >
              <option value="">--</option>
              <option value="1">１回目</option>
              <option value="2">２回目</option>
              <option value="3">３回目</option>
              <option value="4">４回目</option>
              <option value="5">５回目</option>
              <option value="6">６回目</option>
              <option value="7">７回目</option>
              <option value="8">８回目</option>
              <option value="9">９回目</option>
              <option value="10">１０回目</option>
            </select>
          </dd>
        </dl>
        <dl>
          <dt>診察日</dt>
          <dd>
            <input
              type="date"
              v-model="logData.date"
              :disabled="!edit"
          >
          </dd>
        </dl>
        <dl>
          <dt>担当</dt>
          <dd>
            <select
              v-model="logData.tantou"
              :disabled="!edit"
            >
              <option
                v-for="(staff, index) in staffList"
                :key="index"
                :value="index"
              >{{staff}}</option>
            </select>
          </dd>
        </dl>
        <dl>
          <dt>患者番号</dt>
          <dd>{{logData.patientno}}</dd>
        </dl>
        <dl>
          <dt>お名前</dt>
          <dd>
            <span
              @click="showPatientInfo"
            >{{logData.patientname}}</span>
          </dd>
        </dl>
        <dl>
          <dt>電話番号</dt>
          <dd>
            <input
              type="text"
              v-model="userdata.tel"
              :readonly="!edit"
              placeholder="電話番号"
            >
            <input
              type="text"
              v-model="userdata.tel_keitai"
              :readonly="!edit"
              placeholder="携帯電話番号"
            >
          </dd>
        </dl>
        <dl>
          <dt>前回来院日</dt>
          <dd>{{userdata.lastvisitdate}}</dd>
        </dl>
      </div>
    </section>
    <section>
      <h2 class="sectiontitle">初診時主訴</h2>
      <div
        v-if="!edit"
        class="textarea"
      >{{logData.initialclaim}}</div>
      <textarea
        v-else
        v-model="logData.initialclaim"
      ></textarea>
    </section>
    <section
      v-if="!addnew"
    >
      <h2 class="sectiontitle">これまでの処置履歴</h2>
      <TeethHistory />
    </section>
    <section>
      <h2 class="sectiontitle">今回行った処置</h2>
      <TeethTreatments
        :edit="edit"
        :tantou="logData.tantou"
        :date="logData.date"
      />
    </section>
    <section>
      <h2 class="sectiontitle">最近の訴えと対応</h2>
      <div
        v-if="!edit"
        class="textarea"
      >{{logData.recent_claim}}</div>
      <textarea
        v-else
        v-model="logData.recent_claim"
      ></textarea>
    </section>
    <section>
      <h2 class="sectiontitle">DH Check Point</h2>
      <div
        v-if="!edit"
        class="textarea"
      >{{logData.dhcheckpoint}}</div>
      <textarea
        v-else
        v-model="logData.dhcheckpoint"
      ></textarea>
    </section>
    <section>
      <h2 class="sectiontitle">今回気づいて欲しいこと</h2>
      <div class="grid col2">
        <div>
          <label>Ｃ</label>
          <div
            v-if="!edit"
            class="textarea"
          >{{logData.wtnC}}</div>
          <textarea
            v-else
            v-model="logData.wtnC"
          ></textarea>
        </div>
        <div>
          <label>Ｐ</label>
          <div
            v-if="!edit"
            class="textarea"
          >{{logData.wtnP}}</div>
          <textarea
            v-else
            v-model="logData.wtnP"
          ></textarea>
        </div>
        <div>
          <label>力</label>
          <div
            v-if="!edit"
            class="textarea"
          >{{logData.wtnF}}</div>
          <textarea
            v-else
            v-model="logData.wtnF"
          ></textarea>
        </div>
        <div>
          <label>その他</label>
          <div
            v-if="!edit"
            class="textarea"
          >{{logData.wtnOther}}</div>
          <textarea
            v-else
            v-model="logData.wtnOther"
          ></textarea>
        </div>
      </div>
    </section>
    <section>
      <h2 class="sectiontitle">褒めポイント・労いポイント</h2>
      <div
        v-if="!edit"
        class="textarea"
      >{{logData.goodpoint}}</div>
      <textarea
        v-else
        v-model="logData.goodpoint"
      ></textarea>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import TeethHistory from '@/components/TeethHistory'
import TeethTreatments from '@/components/TeethTreatments.vue'
export default {
  components: {
    TeethHistory,
    TeethTreatments
  },
  data () {
    return {
      addnew: false,
      edit: false,
      pnum: '',
      logId: '',
      logData: {
        date: '',
        patientno: '',
        patientname: '',
        numoftimes: '',
        payment: '',
        tantou: '',
        initialclaim: '',
        recent_claim: '',
        dhcheckpoint: '',
        wtnC: '',
        wtnP: '',
        wtnF: '',
        wtnOther: '',
        goodpoint: ''
      },
      userdata: [],
      staffList: []
    }
  },
  mounted () {
    if (this.$route.params.pnum) {
      this.$store.dispatch('Log/loadLogLastTime', this.$route.params.pnum)
      this.addnew = true
      this.edit = true
      const now = new Date()
      this.logData.date = [now.getFullYear(), ('00' + (now.getMonth() + 1)).slice(-2), ('00' + now.getDate()).slice(-2)].join('-')
    } else {
      this.$store.dispatch('Log/loadLog', this.$route.params.id)
    }
    this.$store.watch(
      (state, getters) => getters['Log/logData'],
      (newValue) => {
        this.logData = newValue
        this.logId = this.$store.getters['Log/logId']
      }
    )
    this.$store.watch(
      (state, getters) => getters['Log/userData'],
      (newValue) => {
        console.log(newValue)
        this.userdata = newValue
        this.logData.patientno = newValue.patientno
        this.logData.patientname = newValue.patientname
        this.logData.initialclaim = newValue.initialclaim
      }
    )
    this.$store.dispatch('Staff/load')
    this.$store.watch(
      (state, getters) => getters['Staff/staffList'],
      (newValue) => {
        this.staffList = newValue
      }
    )
  },
  methods: {
    toggleEdit () {
      this.edit = !this.edit
    },
    showPatientInfo () {
      this.$router.push('/patient/' + this.userdata.patientno)
    },
    goback () {
      this.$router.go(-1)
    },
    updateLog () {
      const logdata = { ...this.logData }
      axios
        .post('/wp-json/tpd/v1/log/update', {
          logId: this.logId,
          logData: logdata,
          userData: this.userdata,
          treatments: this.$store.getters['Log/treatment']
        })
        .then((response) => {
          console.log(response.data)
          this.edit = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    deleteLog () {
      if (confirm('記録を削除しますか？')) {
        this.$store.dispatch('Log/deleteLog', this.logId)
        this.$router.push('/log/')
      }
    }
  }
}
</script>
