import { createStore } from 'vuex'
import Authorize from './Authorize.js'
import Log from './Log.js'
import Patient from './Patient.js'
import Staff from './Staff.js'

export default createStore({
  modules: {
    Authorize,
    Log,
    Patient,
    Staff
  }
})
