<template>
  <div class="home">
    <section>
      <header id="pageheader">
        <h1>最近の診察記録</h1>
      </header>
      <div class="list log">
        <ul class="indexrow">
          <li>
            <span>回数</span>
            <span>支払</span>
            <span class="gr3">お名前</span>
            <span>日付</span>
            <span>担当</span>
          </li>
        </ul>
        <ul>
          <logRow
            v-for="(row, index) in logList"
            :key="index"
            :row="row"
          />
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import logRow from '@/components/logRow'

export default {
  components: {
    logRow
  },
  data () {
    return {
      logList: []
    }
  },
  mounted () {
    this.loadLogRecent()
  },
  methods: {
    loadLogRecent () {
      axios
        .get('/wp-json/tpd/v1/log/recent')
        .then((response) => {
          this.logList = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>
