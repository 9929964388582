<template>
  <li>
    <button
      v-if="edit"
      @click="deleteComment"
    >×</button>
    <div class="teethseries">
      <ul class="upper left">
        <li class="UL1" :class="{selected: selected('UL1')}">1</li>
        <li class="UL2" :class="{selected: selected('UL2')}">2</li>
        <li class="UL3" :class="{selected: selected('UL3')}">3</li>
        <li class="UL4" :class="{selected: selected('UL4')}">4</li>
        <li class="UL5" :class="{selected: selected('UL5')}">5</li>
        <li class="UL6" :class="{selected: selected('UL6')}">6</li>
        <li class="UL7" :class="{selected: selected('UL7')}">7</li>
        <li class="UL8" :class="{selected: selected('UL8')}">8</li>
      </ul>
      <ul class="upper right">
        <li class="UR1" :class="{selected: selected('UR1')}">1</li>
        <li class="UR2" :class="{selected: selected('UR2')}">2</li>
        <li class="UR3" :class="{selected: selected('UR3')}">3</li>
        <li class="UR4" :class="{selected: selected('UR4')}">4</li>
        <li class="UR5" :class="{selected: selected('UR5')}">5</li>
        <li class="UR6" :class="{selected: selected('UR6')}">6</li>
        <li class="UR7" :class="{selected: selected('UR7')}">7</li>
        <li class="UR8" :class="{selected: selected('UR8')}">8</li>
      </ul>
      <ul class="lower left">
        <li class="LL1" :class="{selected: selected('LL1')}">1</li>
        <li class="LL2" :class="{selected: selected('LL2')}">2</li>
        <li class="LL3" :class="{selected: selected('LL3')}">3</li>
        <li class="LL4" :class="{selected: selected('LL4')}">4</li>
        <li class="LL5" :class="{selected: selected('LL5')}">5</li>
        <li class="LL6" :class="{selected: selected('LL6')}">6</li>
        <li class="LL7" :class="{selected: selected('LL7')}">7</li>
        <li class="LL8" :class="{selected: selected('LL8')}">8</li>
      </ul>
      <ul class="lower right">
        <li class="LR1" :class="{selected: selected('LR1')}">1</li>
        <li class="LR2" :class="{selected: selected('LR2')}">2</li>
        <li class="LR3" :class="{selected: selected('LR3')}">3</li>
        <li class="LR4" :class="{selected: selected('LR4')}">4</li>
        <li class="LR5" :class="{selected: selected('LR5')}">5</li>
        <li class="LR6" :class="{selected: selected('LR6')}">6</li>
        <li class="LR7" :class="{selected: selected('LR7')}">7</li>
        <li class="LR8" :class="{selected: selected('LR8')}">8</li>
      </ul>
    </div>
    <div class="desc">
      {{decodeURI(log.comment)}}
    </div>
  </li>
</template>

<script>
export default {
  props: ['log', 'edit'],
  computed: {
    selected () {
      return (tooth) => {
        if (this.$props.log.target) {
          return this.$props.log.target.indexOf(tooth) !== -1
        } else {
          return false
        }
      }
    }
  },
  methods: {
    deleteComment (e) {
      const treatments = this.$store.getters['Log/treatment']
      this.$store.commit('Log/treatment', treatments.filter(th => th.ID !== this.$props.log.ID))
    }
  }
}
</script>
