<template>
  <div class="patientdetail">
    <header id="pageheader">
      <h1>スタッフ</h1>
      <div class="control" v-if="add">
        <button
          @click="toggleAdd"
        >キャンセル</button>
      </div>
      <div class="control" v-else>
        <button
          @click="toggleAdd"
        >追加</button>
      </div>
    </header>
    <section>
      <div class="list staff">
        <ul class="indexrow">
          <li>
            <span class="id">ID</span>
            <span class="name">名前</span>
            <span class="delete">削除</span>
          </li>
        </ul>
        <ul>
          <staffRow
            v-for="row in staffList"
            :key="row.id"
            :id="row.id"
            :name="row.name"
          />
          <li v-if="add">
            <span class="id"></span>
            <span class="name">
              <input
                ref="focusThis"
                type="text"
                v-model="staffName"
              >
            </span>
            <span class="delete">
              <button
                class="add"
                @click="addStaff"
              >＋</button>
            </span>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import staffRow from '@/components/staffRow'
export default {
  components: { staffRow },
  data () {
    return {
      add: false,
      staffName: '',
      staffList: []
    }
  },
  mounted () {
    this.$store.dispatch('Staff/load')
    this.$store.watch(
      (state, getters) => getters['Staff/staffList'],
      (newValue) => {
        this.staffList = []
        Object.keys(newValue).forEach(key => {
          this.staffList.push({
            id: key,
            name: newValue[key]
          })
        })
        console.log(this.staffList)
      }
    )
  },
  methods: {
    toggleAdd () {
      this.add = !this.add
      if (this.add) {
        this.$nextTick(() => this.$refs.focusThis.focus())
      }
    },
    addStaff () {
      if (!this.staffName) {
        alert('新しいスタッフのお名前を入力してください')
        this.$refs.focusThis.focus()
        return
      }
      this.$store.dispatch('Staff/add', this.staffName)
      this.add = false
    }
  }
}
</script>
