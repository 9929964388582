import axios from 'axios'

const baseUrl = 'https://tanpopo.sixcore.jp/minidoc'
axios.defaults.baseURL = baseUrl

const state = {
  staffList: []
}

const getters = {
  staffList: (state) => state.staffList
}

const mutations = {
  staffList (state, staffList) {
    state.staffList = staffList
  }
}

const actions = {
  load ({ commit }) {
    axios
      .get('/wp-json/tpd/v1/staff')
      .then((response) => {
        commit('staffList', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  add ({ dispatch }, name) {
    axios
      .post('/wp-json/tpd/v1/staff', {
        name: name
      })
      .then((response) => {
        console.log(response.data)
        dispatch('load')
      })
      .catch((error) => {
        console.log(error)
      })
  },
  delete ({ dispatch }, id) {
    axios
      .delete('/wp-json/tpd/v1/staff/' + id)
      .then((response) => {
        console.log(response.data)
        dispatch('load')
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

const Staff = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default Staff
