import axios from 'axios'

const baseUrl = 'https://tanpopo.sixcore.jp/minidoc'
axios.defaults.baseURL = baseUrl

const state = {
  logId: '',
  logList: [],
  logData: [],
  userData: [],
  history: [],
  other: [],
  treatment: []
}

const getters = {
  logId: (state) => state.logId,
  logList: (state) => state.logList,
  logData: (state) => state.logData,
  userData: (state) => state.userData,
  history: (state) => state.history,
  other: (state) => state.other,
  treatment: (state) => state.treatment
}

const mutations = {
  logId (state, logId) {
    state.logId = logId
  },
  logList (state, logList) {
    state.logList = logList
  },
  logData (state, logData) {
    state.logData = logData
  },
  userData (state, userData) {
    state.userData = userData
  },
  history (state, history) {
    state.history = history
  },
  other (state, other) {
    state.other = other
  },
  treatment (state, treatment) {
    state.treatment = treatment
  }
}

const actions = {
  async loadlist ({ commit }) {
    await axios
      .get('/wp-json/tpd/v1/log/')
      .then((response) => {
        commit('logList', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async loadlistRecent ({ commit }) {
    await axios
      .get('/wp-json/tpd/v1/log/recent')
      .then((response) => {
        commit('logList', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async loadLog ({ commit }, id) {
    if (id) {
      await axios
        .get('/wp-json/tpd/v1/log/' + id)
        .then((response) => {
          console.log(response.data)
          commit('logId', response.data.logId)
          commit('logData', response.data.logdata)
          commit('userData', response.data.userdata)
          commit('history', response.data.history)
          commit('other', response.data.other)
          commit('treatment', response.data.treatments)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  loadLogLastTime ({ commit }, pnum) {
    if (pnum) {
      axios
        .get('/wp-json/tpd/v1/log/lasttime/' + pnum)
        .then((response) => {
          console.log(response.data)
          commit('logId', '')
          commit('logData', response.data.logdata)
          commit('userData', response.data.userdata)
          commit('history', response.data.history)
          commit('other', response.data.other)
          commit('treatment', [])
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  async loadPatientInfo ({ commit }, pnum) {
    if (pnum) {
      await axios
        .get('/wp-json/tpd/v1/patient/' + pnum)
        .then((response) => {
          console.log(response.data)
          commit('userData', response.data.userdata)
          commit('history', response.data.history)
          commit('other', response.data.other)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  updateLog ({ getters }) {
    const postdata = {
      logId: getters.logId,
      logdata: getters.logData,
      treatments: getters.treatment
    }
    axios
      .post('/wp-json/tpd/v1/log/update/', postdata)
      .then((response) => {
        console.log(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  addComment ({ getters, commit }, arr) {
    // index: this.treatmentThisTime.length > 0 ? this.treatmentThisTime.length + 1 : 1,
    let treatments = getters.treatment
    if (treatments.length === 0) {
      arr.ID = 1
    } else {
      arr.ID = parseInt(treatments.slice(-1)[0].ID) + 1
    }
    treatments.push(arr)
    treatments = treatments.filter((element) => {
      return element !== null
    })
    console.log(treatments)
    commit('treatment', treatments)
  },
  async deleteLog ({ dispatch }, id) {
    await axios
      .post('/wp-json/tpd/v1/log/delete/', {
        logid: id
      })
      .then((response) => {
        console.log(response.data)
        dispatch('loadlist')
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async deleteComment ({ getters, commit }, id) {
    await axios
      .post('/wp-json/tpd/v1/comment/delete/', {
        commentid: id
      })
      .then((response) => {
        console.log(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

const Log = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default Log
