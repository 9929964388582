import axios from 'axios'
import router from '@/router'

const baseUrl = 'https://tanpopo.sixcore.jp/minidoc'
axios.defaults.baseURL = baseUrl

const state = {
  patientList: []
}
const getters = {
  patientList: (state) => state.patientList
}
const mutations = {
  patientList (state, patientList) {
    state.patientList = patientList
  }
}
const actions = {
  async loadPatients ({ commit }) {
    await axios
      .get('/wp-json/tpd/v1/patients')
      .then((response) => {
        console.log(response.data)
        commit('patientList', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async addPatient ({ dispatch }, data) {
    await axios
      .post('/wp-json/tpd/v1/patient/add/', data)
      .then((response) => {
        console.log(response.data)
        router.push('/patient')
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async deletePatient (context, pnum) {
    await axios
      .delete('/wp-json/tpd/v1/patient/delete/' + pnum)
      .then((response) => {
        console.log(response.data)
        router.push('/patient')
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

const Patient = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default Patient
