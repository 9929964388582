<template>
  <li @click.stop="showDetail">
    <span>{{kubun}}</span>
    <span>{{payment}}</span>
    <span class="gr3">{{pname}} ({{pnum}})</span>
    <span>{{date}}</span>
    <span>{{tantou}}</span>
  </li>
</template>

<script>
export default {
  props: ['row'],
  computed: {
    kubun () {
      return this.$props.row.numoftimes
    },
    date () {
      return this.$props.row.date
    },
    pnum () {
      return this.$props.row.patientno
    },
    pname () {
      return this.$props.row.patientname
    },
    tantou () {
      return this.$props.row.tantou
    },
    payment () {
      let payment
      switch (this.$props.row.payment) {
        case 'insurance':
          payment = '保'
          break
        case 'self':
          payment = '自'
          break
        default:
          payment = ''
      }
      return payment
    }
  },
  methods: {
    deleteLog (id) {
      if (confirm('削除してよろしいですか？')) {
        this.$emit('delete', id)
      }
    },
    showDetail () {
      if (!this.$props.row.ID) {
        alert('データが破損しています')
        return
      }
      this.$router.push('/log/' + this.$props.row.ID)
    }
  }
}
</script>
