<template>
  <div class="patientdetail">
    <header id="pageheader">
      <h1>患者情報</h1>
      <div
        class="control"
        v-if="edit"
      >
        <button
          @click="updatePatientInfo"
        >保存</button>
        <button
          @click="toggleEdit"
        >キャンセル</button>
      </div>
      <div
        class="control"
        v-else
      >
        <button
          @click="addNew"
        >＋診察記録</button>
        <button
          @click="toggleEdit"
        >患者情報編集</button>
        <button
          @click="deletePatient"
        >削除</button>
      </div>
    </header>

    <section>
      <div class="patientsummary">
        <dl>
          <dt>患者番号</dt>
          <dd>
            <input
              type="number"
              v-model="patientInfo.patientno"
              :readonly="!edit"
            >
          </dd>
        </dl>
        <dl>
          <dt>お名前</dt>
          <dd>
            <input
              type="text"
              v-model="patientInfo.kana"
              :readonly="!edit"
              placeholder="よみがな"
              class="small"
            >
            <input
              type="text"
              v-model="patientInfo.patientname"
              placeholder="お名前"
              :readonly="!edit"
            >
          </dd>
        </dl>
        <dl>
          <dt>生年月日</dt>
          <dd>
            <input
              type="date"
              v-model="patientInfo.birthday"
              :readonly="!edit"
            >
          </dd>
        </dl>
        <dl>
          <dt>住所</dt>
          <dd>
            <input
              type="text"
              v-model="patientInfo.zip"
              placeholder="〒"
              :readonly="!edit"
              class="small"
            >
            <input
              type="text"
              v-model="patientInfo.addr"
              placeholder="住所"
              :readonly="!edit"
            >
          </dd>
        </dl>
        <dl>
          <dt>電話番号</dt>
          <dd>
            <input
              type="text"
              v-model="patientInfo.tel"
              :readonly="!edit"
              placeholder="電話番号"
            >
            <input
              type="text"
              v-model="patientInfo.tel_keitai"
              :readonly="!edit"
              placeholder="携帯電話番号"
            >
          </dd>
        </dl>
        <dl>
          <dt>性別</dt>
          <dd>
            <select
              type="text"
              v-model="patientInfo.sex"
              :disabled="!edit"
            >
              <option>男性</option>
              <option>女性</option>
          </select>
          </dd>
        </dl>
        <dl>
          <dt>前回来院日</dt>
          <dd>
            <input
              type="date"
              v-model="patientInfo.lastvisitdate"
              :readonly="!edit"
            >
          </dd>
        </dl>
        <dl>
          <dt>初診来院日</dt>
          <dd>
            <input
              type="date"
              v-model="patientInfo.firstvisitdate"
              :readonly="!edit"
            >
          </dd>
        </dl>
      </div>
    </section>
    <section>
      <h2 class="sectiontitle">初診時主訴</h2>
      <div
        v-if="!edit"
        class="textarea"
      >{{patientInfo.initialclaim}}</div>
      <textarea
        v-else
        v-model="patientInfo.initialclaim"
      ></textarea>
    </section>

    <section>
      <h2 class="sectiontitle">処置履歴</h2>
      <TeethHistory
        :pnum="patientInfo.patientno"
        :teethArray="teethHistory"
      />
    </section>
    <section>
      <h2 class="sectiontitle">診察記録</h2>
      <div class="list log">
        <ul class="indexrow">
          <li>
            <span>何回目</span>
            <span>支払</span>
            <span class="gr3">お名前</span>
            <span>日付</span>
            <span>担当</span>
          </li>
        </ul>
        <ul>
          <logRow
            v-for="(row, index) in logList"
            :key="index"
            :row="row"
          />
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import TeethHistory from '@/components/TeethHistory'
import logRow from '@/components/logRow'
export default {
  components: {
    TeethHistory,
    logRow
  },
  data () {
    return {
      edit: false,
      patientInfo: [],
      teethHistory: [],
      logList: []
    }
  },
  mounted () {
    this.getlogsRelPatient(this.$route.params.id)
    this.$store.dispatch('Log/loadPatientInfo', this.$route.params.id)
    this.$store.watch(
      (state, getters) => getters['Log/userData'],
      (newValue) => {
        this.patientInfo = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters['Log/history'],
      (newValue) => {
        this.teethHistory = newValue
      }
    )
  },
  methods: {
    updatePatientInfo () {
      axios
        .post('/wp-json/tpd/v1/patient/update', this.patientInfo)
        .then((response) => {
          this.edit = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    toggleEdit () {
      this.edit = !this.edit
    },
    addNew () {
      this.$router.push('/log/add/' + this.$route.params.id)
    },
    deletePatient () {
      if (confirm(`${this.patientInfo.patientname} を削除しますか？`)) {
        this.$store.dispatch('Patient/deletePatient', this.patientInfo.patientno)
      }
    },
    getlogsRelPatient (pnum) {
      axios
        .get('/wp-json/tpd/v1/log/rel/' + pnum)
        .then((response) => {
          this.logList = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>
