import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import HomeView from '../views/HomeView.vue'
import LoginDialogue from '@/views/LoginDialogue.vue'
import PatientList from '@/views/PatientList.vue'
import PatientDetail from '@/views/PatientDetail.vue'
import PatientAdd from '@/views/PatientAdd.vue'
import LogList from '@/views/LogList.vue'
import LogDetail from '@/views/LogDetail.vue'
import ClinicStaff from '@/views/ClinicStaff.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginDialogue
  },
  {
    path: '/patient',
    name: 'patient',
    component: PatientList
  },
  {
    path: '/patient/:id',
    name: 'patient-detail',
    sensitive: true,
    component: PatientDetail
  },
  {
    path: '/patient/add',
    name: 'patient-add',
    sensitive: true,
    component: PatientAdd
  },
  {
    path: '/log',
    name: 'log',
    component: LogList
  },
  {
    path: '/log/:id',
    name: 'log-detail',
    sensitive: true,
    component: LogDetail
  },
  {
    path: '/log/add/:pnum',
    name: 'log-add',
    sensitive: true,
    component: LogDetail
  },
  {
    path: '/staff',
    name: 'staff',
    component: ClinicStaff
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['Authorize/isAuthenticated']
  if (to.name !== 'login' && !isAuthenticated) next({ name: 'login' })
  else next()
})

export default router
