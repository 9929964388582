<template>
  <li
    :key="row.ID"
    @click.stop="showDetail(row.meta.patientno)"
  >
    <span>{{row.meta.patientno}}</span>
    <span class="gr4">{{row.name}}</span>
    <span>{{tel}}</span>
    <span>{{row.meta.lastvisitdate}}</span>
  </li>
</template>

<script>
export default {
  props: ['row'],
  computed: {
    tel () {
      return this.$props.row.meta.tel_keitai ? this.$props.row.meta.tel_keitai : this.$props.row.meta.tel
    }
  },
  methods: {
    showDetail (id) {
      this.$router.push('/patient/' + id)
    }
  }
}
</script>
