<template>
  <div id="treatments">
    <div
      class="teethseries"
      v-if="edit"
    >
        <ul class="upper left">
          <li class="UL1" :class="{selected: teethArray.UL1}" @click="toggleSelect">1</li>
          <li class="UL2" :class="{selected: teethArray.UL2}" @click="toggleSelect">2</li>
          <li class="UL3" :class="{selected: teethArray.UL3}" @click="toggleSelect">3</li>
          <li class="UL4" :class="{selected: teethArray.UL4}" @click="toggleSelect">4</li>
          <li class="UL5" :class="{selected: teethArray.UL5}" @click="toggleSelect">5</li>
          <li class="UL6" :class="{selected: teethArray.UL6}" @click="toggleSelect">6</li>
          <li class="UL7" :class="{selected: teethArray.UL7}" @click="toggleSelect">7</li>
          <li class="UL8" :class="{selected: teethArray.UL8}" @click="toggleSelect">8</li>
        </ul>
        <ul class="upper right">
          <li class="UR1" :class="{selected: teethArray.UR1}" @click="toggleSelect">1</li>
          <li class="UR2" :class="{selected: teethArray.UR2}" @click="toggleSelect">2</li>
          <li class="UR3" :class="{selected: teethArray.UR3}" @click="toggleSelect">3</li>
          <li class="UR4" :class="{selected: teethArray.UR4}" @click="toggleSelect">4</li>
          <li class="UR5" :class="{selected: teethArray.UR5}" @click="toggleSelect">5</li>
          <li class="UR6" :class="{selected: teethArray.UR6}" @click="toggleSelect">6</li>
          <li class="UR7" :class="{selected: teethArray.UR7}" @click="toggleSelect">7</li>
          <li class="UR8" :class="{selected: teethArray.UR8}" @click="toggleSelect">8</li>
        </ul>
        <ul class="lower left">
          <li class="LL1" :class="{selected: teethArray.LL1}" @click="toggleSelect">1</li>
          <li class="LL2" :class="{selected: teethArray.LL2}" @click="toggleSelect">2</li>
          <li class="LL3" :class="{selected: teethArray.LL3}" @click="toggleSelect">3</li>
          <li class="LL4" :class="{selected: teethArray.LL4}" @click="toggleSelect">4</li>
          <li class="LL5" :class="{selected: teethArray.LL5}" @click="toggleSelect">5</li>
          <li class="LL6" :class="{selected: teethArray.LL6}" @click="toggleSelect">6</li>
          <li class="LL7" :class="{selected: teethArray.LL7}" @click="toggleSelect">7</li>
          <li class="LL8" :class="{selected: teethArray.LL8}" @click="toggleSelect">8</li>
        </ul>
        <ul class="lower right">
          <li class="LR1" :class="{selected: teethArray.LR1}" @click="toggleSelect">1</li>
          <li class="LR2" :class="{selected: teethArray.LR2}" @click="toggleSelect">2</li>
          <li class="LR3" :class="{selected: teethArray.LR3}" @click="toggleSelect">3</li>
          <li class="LR4" :class="{selected: teethArray.LR4}" @click="toggleSelect">4</li>
          <li class="LR5" :class="{selected: teethArray.LR5}" @click="toggleSelect">5</li>
          <li class="LR6" :class="{selected: teethArray.LR6}" @click="toggleSelect">6</li>
          <li class="LR7" :class="{selected: teethArray.LR7}" @click="toggleSelect">7</li>
          <li class="LR8" :class="{selected: teethArray.LR8}" @click="toggleSelect">8</li>
        </ul>
      </div>
      <div
        class="addcomment"
        v-if="edit"
      >
        <textarea
          v-model="comment"
        ></textarea>
        <button
          class="add"
          @click="addTreatment()"
        >処置を追加</button>
        <button
          class="other"
          @click="addTreatmentByOther()"
        >他院処置</button>
      </div>
      <ul id="treatment_log">
        <TeethIndicator
          v-for="(log, index) in treatmentThisTime"
          :key="index"
          :log="log"
          :edit="edit"
        />
      </ul>
  </div>
</template>

<script>
import TeethIndicator from '@/components/TeethIndicator.vue'
export default {
  props: ['edit', 'tantou', 'date'],
  components: { TeethIndicator },
  data () {
    return {
      teethArray: {
        UL1: false,
        UL2: false,
        UL3: false,
        UL4: false,
        UL5: false,
        UL6: false,
        UL7: false,
        UL8: false,
        UR1: false,
        UR2: false,
        UR3: false,
        UR4: false,
        UR5: false,
        UR6: false,
        UR7: false,
        UR8: false,
        LL1: false,
        LL2: false,
        LL3: false,
        LL4: false,
        LL5: false,
        LL6: false,
        LL7: false,
        LL8: false,
        LR1: false,
        LR2: false,
        LR3: false,
        LR4: false,
        LR5: false,
        LR6: false,
        LR7: false,
        LR8: false
      },
      selected: [],
      comment: '',
      treatmentThisTime: []
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters['Log/treatment'],
      (newValue) => {
        this.treatmentThisTime = newValue.filter((item) => item !== null)
      }
    )
  },
  methods: {
    toggleSelect (e) {
      const toothID = e.target.classList[0]
      this.teethArray[toothID] = !this.teethArray[toothID]
      if (this.teethArray[toothID]) {
        this.selected.push(toothID)
      } else {
        const newArray = this.selected.filter(th => th !== toothID)
        this.selected = newArray
      }
    },
    // 自院処置
    addTreatment () {
      if (!this.comment) {
        return
      }
      this.$store.dispatch('Log/addComment', {
        target: this.selected,
        comment: encodeURI(this.comment),
        tantou: this.$props.tantou,
        date: this.$props.date,
        other: false
      })
      this.resetTeethSelect()
      this.comment = ''
      this.selected = []
    },
    // 他院処置
    addTreatmentByOther () {
      if (!this.comment) {
        return
      }
      this.$store.dispatch('Log/addComment', {
        target: this.selected,
        comment: encodeURI(this.comment),
        tantou: this.$props.tantou,
        date: this.$props.date,
        other: true
      })
      this.resetTeethSelect()
      this.comment = ''
      this.selected = []
    },
    resetTeethSelect () {
      for (const key in this.teethArray) {
        this.teethArray[key] = false
      }
    }
  }
}
</script>
