<template>
  <form id="loginform">
    <div>
      <dl>
        <dt><label for="username">ユーザー名</label></dt>
        <dd>
          <input
            type="text"
            class="form-control"
            id="username"
            placeholder="ユーザー名"
            v-model="authData.username"
          >
        </dd>
      </dl>
      <dl>
        <dt><label for="password">パスワード</label></dt>
        <dd>
          <input
            type="password"
            class="form-control"
            id="password"
            placeholder="パスワード"
            v-model="authData.password"
          >
        </dd>
      </dl>
      <div class="sleeve">
        <button
          class="w-100 btn btn-lg btn-primary"
          type="submit"
          @click.prevent="login"
        >ログイン</button>
      </div>
      <p>&copy;Tanpopo Dental Clinic 2022</p>
    </div>
  </form>
</template>

<script>
export default {
  name: 'LoginDialogue',
  data () {
    return {
      authData: {
        username: null,
        password: null
      },
      users: [
        {
          name: '',
          password: ''
        }
      ]
    }
  },
  mounted () {
    this.authData.username = this.users[0].name
    this.authData.password = this.users[0].password
  },
  methods: {
    login () {
      this.$store.dispatch('Authorize/login', this.authData)
    }
  }
}
</script>
