<template>
  <aside>
    <router-link to="/">
      <h1>
        <svg width="40" height="40"><use xlink:href="#tapopo-logo"></use></svg>
        <span>Tanpopo<br>Dental Clinic</span><i>ミニドック受診記録</i>
      </h1>
    </router-link>
    <nav>
      <ul>
        <template v-if="isAuthenticated">
        <li>
          <router-link
            to="/patient"
            class="patient"
            :class="{active: current==='patient' || current==='patient-detail' || current==='patient-add'}"
          >患者リスト</router-link>
        </li>
        <li>
          <router-link
            to="/log"
            class="log"
            :class="{active: current==='log' || current==='log-detail' || current==='log-add'}"
          >診察記録</router-link>
        </li>
        <li>
          <router-link
            to="/staff"
            class="staff"
            :class="{active: current==='staff'}"
          >スタッフ</router-link>
        </li>
        <li>
          <a
            href="#"
            class="logout"
            @click="logout"
            :class="{active: current==='login'}"
          >ログアウト</a>
        </li>
        </template>
        <template v-else>
        <li><router-link to="/login">ログイン</router-link></li>
        </template>
      </ul>
    </nav>
  </aside>
  <main>
    <router-view/>
  </main>
</template>

<script>
export default {
  data () {
    return {
      current: '',
      isAuthenticated: false
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters['Authorize/isAuthenticated'],
      (newValue) => { this.isAuthenticated = newValue }
    )
  },
  watch: {
    $route (to) {
      this.current = to.name
    }
  },
  methods: {
    logout () {
      if (confirm('ログアウトしますか？')) {
        this.$store.dispatch('Authorize/logout')
      }
    }
  }
}
</script>
