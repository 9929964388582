<template>
  <div class="loglist">
    <header id="pageheader">
      <h1>診察記録（直近６ヶ月）</h1>
    </header>
    <section>
      <div class="list log">
        <ul class="indexrow">
          <li>
            <span>何回目</span>
            <span>支払</span>
            <span class="gr3">お名前</span>
            <span>日付</span>
            <span>担当</span>
          </li>
        </ul>
        <ul>
          <logRow
            v-for="(row, index) in logList"
            :key="index"
            :row="row"
          />
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
// import axios from 'axios'
import logRow from '@/components/logRow'
export default {
  components: {
    logRow
  },
  data () {
    return {
      logList: []
    }
  },
  mounted () {
    this.$store.dispatch('Log/loadlist')
    this.$store.watch(
      (state, getters) => getters['Log/logList'],
      (newValue) => {
        this.logList = newValue.filter((item) => item !== null)
      }
    )
    // this.loadRecentLog()
  },
  methods: {
    // loadRecentLog () {
    //   axios
    //     .get('/wp-json/tpd/v1/log')
    //     .then((response) => {
    //       this.logList = response.data
    //       console.log(response.data)
    //     })
    //     .catch((error) => {
    //       console.log(error)
    //     })
    // }
  }
}
</script>
