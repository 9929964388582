<template>
  <li>
    <div class="id">{{id}}</div>
    <div class="name">{{name}}</div>
    <div class="delete">
      <button
        @click="deleteStaff(id)"
      >×</button>
    </div>
  </li>
</template>

<script>
export default {
  props: ['name', 'id'],
  methods: {
    deleteStaff (id) {
      if (confirm(`${this.name}を削除しますか？
      `)) {
        this.$store.dispatch('Staff/delete', id)
      }
    }
  }
}
</script>
