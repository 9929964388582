<template>
  <div class="patientdetail">
    <header id="pageheader">
      <h1>新規患者追加</h1>
      <div
        class="control"
      >
        <button
          @click="addPatient"
        >追加</button>
        <button
          @click="goback"
        >キャンセル</button>
      </div>
    </header>

    <section>
      <div class="patientsummary">
        <dl>
          <dt>患者番号</dt>
          <dd>
            <input
              type="number"
              v-model="patientInfo.patientno"
            >
          </dd>
        </dl>
        <dl>
          <dt>お名前</dt>
          <dd>
            <input
              type="text"
              v-model="patientInfo.kana"
              placeholder="よみがな"
              class="small"
            >
            <input
              type="text"
              v-model="patientInfo.patientname"
              placeholder="お名前"
            >
          </dd>
        </dl>
        <dl>
          <dt>生年月日</dt>
          <dd>
            <input
              type="date"
              v-model="patientInfo.birthday"
            >
          </dd>
        </dl>
        <dl>
          <dt>住所</dt>
          <dd>
            <input
              type="text"
              v-model="patientInfo.zip"
              placeholder="〒"
              class="small"
            >
            <input
              type="text"
              v-model="patientInfo.addr"
              placeholder="住所"
            >
          </dd>
        </dl>
        <dl>
          <dt>電話番号</dt>
          <dd>
            <input
              type="text"
              v-model="patientInfo.tel"
              placeholder="電話番号"
            >
            <input
              type="text"
              v-model="patientInfo.tel_keitai"
              placeholder="携帯電話番号"
            >
          </dd>
        </dl>
        <dl>
          <dt>性別</dt>
          <dd>
            <select
              type="text"
              v-model="patientInfo.sex"
            >
              <option>男性</option>
              <option>女性</option>
          </select>
          </dd>
        </dl>
      </div>
    </section>
    <section>
      <h2 class="sectiontitle">初診時主訴</h2>
      <textarea
        v-model="patientInfo.initialclaim"
      ></textarea>
    </section>
  </div>
</template>

<script>
// import axios from 'axios'
export default {
  data () {
    return {
      patientInfo: {
        patientno: '',
        patientname: '',
        kana: '',
        birthday: '',
        zip: '',
        addr: '',
        sex: '',
        tel: '',
        tel_keitai: '',
        initialClaim: ''
      },
      errors: []
    }
  },
  methods: {
    validation () {
      this.errors = []
      if (!this.patientInfo.patientno) this.errors.push('患者番号を入力してください')
      if (!this.patientInfo.patientname) this.errors.push('お名前を入力してください')
      return !this.errors.length
    },
    addPatient () {
      if (!this.validation()) {
        alert(`患者番号を入力してください
お名前を入力してください
        `)
      } else {
        this.$store.dispatch('Patient/addPatient', this.patientInfo)
      }
    },
    goback () {
      this.$router.go(-1)
    }
  }
}
</script>
