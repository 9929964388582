<template>
  <div class="patientlist">
    <header id="pageheader">
      <h1>患者リスト</h1>
      <div class="control">
        <button
          @click="addNew"
        >+追加</button>
      </div>
    </header>
    <section>
      <div id="search-patient">
        <input
          type="text"
          placeholder="患者番号orお名前"
          v-model="searchText"
        >
      </div>
      <div class="list patient">
        <ul class="indexrow">
          <li>
            <span>No.</span>
            <span class="gr4">お名前</span>
            <span>TEL</span>
            <span>前回診察</span>
          </li>
        </ul>
        <ul>
          <patientRow
            v-for="(row, index) in patientList"
            :key="index"
            :row="row"
          />
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import patientRow from '@/components/patientRow'
export default {
  components: {
    patientRow
  },
  data () {
    return {
      patientList: [],
      searchText: ''
    }
  },
  mounted () {
    this.$store.dispatch('Patient/loadPatients')
    this.$store.watch(
      (state, getters) => getters['Patient/patientList'],
      (newValue) => {
        this.patientList = newValue
      }
    )
  },
  methods: {
    searchPatient (str) {
      axios
        .post('/wp-json/tpd/v1/patient/search', {
          str: str
        })
        .then((response) => {
          this.patientList = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    addNew () {
      this.$router.push('/patient/add')
    }
  },
  watch: {
    searchText (val) {
      this.searchPatient(val)
    }
  }
}
</script>
